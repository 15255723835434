import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {Typography, TextField, Button, Tooltip, IconButton} from "@material-ui/core";
import { withNamespaces } from "react-i18next";
import { colors } from "../../theme";
import { isMobile } from "react-device-detect";
import Swal from "sweetalert2";
import UnlockModal from "../unlock/unlockModal.jsx";

import Background from "../../assets/bckFoodNear.png";
import iconCopy from "../../assets/copyIcon.png"

import ENS from "ethjs-ens";
import { CONNECTION_CONNECTED, CONNECTION_DISCONNECTED } from "../../constants";

import Store from "../../stores";
const emitter = Store.emitter;
const store = Store.store;
const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    //maxWidth: "1200px",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  investedContainerLoggedOut: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "100%",
    //marginTop: "40px",
    [theme.breakpoints.up("md")]: {
      minWidth: "1400px",
    },
  },
  investedContainer: {
    display: "flex",
    flex: 1,
    borderRadius: "30px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "85%",

    //marginTop: "40px",
    [theme.breakpoints.up("md")]: {
      minWidth: "1400px",
    },
  },

  portfolioContainer: {
    //width: "100%",
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  header: {
    width: "100%",
    top: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    flexWrap: "wrap",
  },

  gray: {
    color: colors.darkGray,
  },
  between: {
    width: "40px",
    height: "40px",
  },
  titleBalance: {
    //padding: "28px 30px",

    //background: colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  prettyAlign: {
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    fontSize: "1em",
    marginRight: "6px",
  },
  heading: {
    //marginTop: "40px",
    display: "flex",
    flexDirection: "column",
    minWidth: "200px",
    alignItems: "flex-end",
    textAlign: "center",
    color: "#eaeaea",
    textTransform: "uppercase",
  },
  headingName: {
    display: "flex",
    alignItems: "center",
    width: "325px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      flex: 1,
    },
  },
  flexy: {
    display: "flex",
    alignItems: "center",
  },
  vault: {
    borderBottom: "1px solid rgba(25, 101, 233, 0.2)",
    padding: "12px",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  sectionHeading: {
    color: colors.darkGray,
    width: "100%",
    marginLeft: "54px",
  },
  inline: {
    display: "flex",
    alignItems: "baseline",
  },
  symbol: {
    paddingLeft: "6px",
  },
  symbolAt: {
    paddingLeft: "6px",
    color: colors.darkGray,
  },
  basedOnContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  logo: {
    display: "flex",
    //paddingBottom: "60px",
    alignItems: "center",
    flex: 1,

    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },

    "& img": {
      cursor: "pointer",
    },
  },
  copyAddress:{
    color: colors.black,
    backgroundColor: colors.white,
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "500px",
    padding: "0 20px 0 20px",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      flex: 2
    }

  },
  walletAddress: {
    color: colors.white,
    padding: "12px",
    border: "2px solid rgb(174, 174, 174)",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      border: "2px solid " + colors.white,
      background: "rgba(47, 128, 237, 0.1)",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
      position: "absolute",
      top: "90px",
      border: "1px solid " + colors.white,
      background: colors.white,
    },
  },
  connectedDot: {
    background: colors.compoundGreen,
    opacity: "1",
    borderRadius: "10px",
    width: "10px",
    height: "10px",
    marginRight: "3px",
    marginLeft: "6px",
  },
  icon: {
    display: "flex",
  },
  links: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flex: "0",
    },
  },
  link: {
    marginRight: "20px",
    cursor: "pointer",
    textTransform: "uppercase",
    fontSize: "20px",
    color: "#ffcc33",
    padding: "7px",
    borderRadius: "30px",
    "&:hover": {
      //backgroundColor: "rgba(255,255,255,0.5)",
      textDecoration: "underline",
    },

    "& a": {
      textDecoration: "none",
      color: "#ffcc33",
    },
  },

  contribute: {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#177bd3",
    textTransform: "uppercase",
    fontSize: "30px",
  },

  contract: {
    width: "100%",
    height: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#eaeaea",
    textTransform: "uppercase",
    textAlign: "center",
  },
  token: {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f3ba2f",
    justifyContent: "center",
    textTransform: "uppercase",
    textAlign: "center",
  },
  buyModal: {
    width: "100%",
    display: "flex",
    backgroundColor: "white",
    borderRadius: "30px",
    filter: "drop-shadow(0px 3px 28.5px rgba(0,0,0,0.68))",
    color: colors.black,
    padding: "20px 30px 60px",
    "& h3": {
      marginTop: "15px",
      marginBottom: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  buyModalImage: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
  },
  buyModalContent: {
    display: "flex",
    flex: "2",
    flexDirection: "column",
  },
  separator: {
    width: "3px",
    background: "#decfcf",
    margin: "0 30px 0 0",
  },

  input: {
    "&::placeholder": {
      color: "#797979",
    },
    color: "#444444",
    backgroundColor: "#D8D8D8",
    borderRadius: "20px !important",
  },
  cssOutlinedInput: {
    borderRadius: "30px !important",
  },
  notchedOutline: {
    border: "none",
    borderColor: "#444444 !important",
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },

  actionButton: {
    color: colors.black,
    borderRadius: "20px",
    backgroundColor: "#f3ba2f",
    borderColor: "#f3ba2f !important",
    marginTop: "20px",
    width: "30%",
    "&:hover": {
      border: "1px solid",
      borderColor: "white !important",
      backgroundColor: "#f3ba2f",
    },
  },

  actionButtonDisabled: {
    color: colors.black,
    borderRadius: "20px",
    backgroundColor: "#f3ba2f",
    borderColor: "#f3ba2f !important",
    marginTop: "20px",
    "&:hover": {
      border: "1px solid",
      borderColor: "white !important",
      backgroundColor: "#f3ba2f",
    },
  },

  buttonText: {
    fontSize: "24px",
    fontWeight: "500",
  },

  description: {
    marginTop: "28px",
    maxWidth: "700px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#eaeaea",
    textTransform: "uppercase",
    textAlign: "center",
  },

  row: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "400px",
    justifyContent: "center",
    minWidth: "100%",
    flexDirection: "row",
    //alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  referralTitle: {
    padding: "12px",
    borderWidth: "2px 1px 2px 2px",
    border: "solid white",
    borderRadius: "0.75rem 0 0 0.75rem",
    background: "linear-gradient(0deg, #177bd3 0%, #06070a 100%)",
    color: colors.white,
    minWidth: "170px",
  },
  referralLink: {
    padding: "12px",
    border: "2px solid white",
    background: colors.black,
    color: "#1679d0",
    minWidth: "35%",
  },
  referralCopy: {
    padding: "12px",
    border: "2px solid white",
    borderRadius: "0 0.75rem 0.75rem 0",
    background: colors.white,
    color: colors.black,
    minWidth: "170px",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid",
      borderColor: "#dbdbdb !important",
      backgroundColor: colors.white,
    },
  },

  disaclaimer: {
    padding: "10px 20px",
    border: "1px solid rgb(174, 174, 174)",
    borderRadius: "0.75rem",
    background: "linear-gradient(180deg, #177bd3 0%, #06070a 100%)",
    color: colors.white,
  },

  refBalance: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
  },
  goldBalance: {
    color: "#e6a147",
  },

  footer: {
    margin: "30px 0 20px",
    color: "white",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "none !important",
    },
  },
  footerModal:{
    display:"flex",
    justifyContent: "left",
    [theme.breakpoints.down("sm")]: {
     width: "100%",
      flexDirection: "column"
    },
  },
  footerText:{
    fontSize: "1em",
    fontWeight: 300,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
     marginTop: "15px",
    }
  },
  footerAddress:{
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    },

  buttonClip:{
    [theme.breakpoints.down("sm")]: {
     display: "grid"
    },
  }
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    marginTop: "0px",
  },
}))(Tooltip);

class Presale extends Component {
  constructor(props) {
    super();

    this.state = {
      modalOpen: false,
      account: store.getStore("account"),
      gasPrice: "",
      amount: "",
      tokenPrice: 1 / 12121212,
    };

    this.handleAmountChange = this.handleAmountChange.bind(this);
  }

  componentWillMount() {
    emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, this.connectionDisconnected);
  }

  componentWillUnmount() {
    emitter.removeListener(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.removeListener(
        CONNECTION_DISCONNECTED,
        this.connectionDisconnected
    );
  }

  connectionConnected = () => {
    this.setState({ account: store.getStore("account") });
    this.setAddressEnsName();
    this.getGasPrice();
  };

  connectionDisconnected = () => {
    this.setState({ account: store.getStore("account") });
  };

  getGasPrice = async () => {
    const gasPrice = await store._getGasPrice();
    console.log(gasPrice)
    this.setState({ gasPrice: (gasPrice * 0.9).toFixed(5) });
    console.log(this.state.gasPrice);
  };

  setAddressEnsName = async () => {
    const provider = store.getStore("web3context").library.provider;
    const account = store.getStore("account");
    const { address } = account;
    const network = provider.networkVersion;
    console.log(network)
    const ens = new ENS({ provider, network});
    const addressEnsName = await ens.reverse(address).catch(() => {});
    if (addressEnsName) {
      this.setState({ addressEnsName });
    }
  };

  handleAmountChange = (e) => {
    console.log(e)
    this.setState({
      amount: e.target.value ? Math.abs(e.target.value) : "",
    });
  };

  buyToken = () => {
    if (this.state.amount < 100){
      let temp = {target: {value: "100"}}
      Toast.fire({
        icon: 'error',
        title: 'Amount have to be more or equal than 100 FON'
      })
     this.handleAmountChange(temp)
      return "unsuccess"
    }
    let amount = this.state.amount * this.state.tokenPrice;
    if (isMobile) {
      Swal.fire("Please visit desktop to connect MetaMask wallet");
    } else {
      let callback = store.buyToken(store.getStore("account"), amount.toFixed(5), this.state.gasPrice)
      callback.then((e) => {
        console.log(e)
      })
      Toast.fire({
        icon: 'success',
        title: 'Redirecting to wallet!'
      })

    }
  };
  copyCodeToClipboard = () => {
    const el = "0x9C1f357A3265047fbC55C482eD6f69bDd8F0dA74"
    navigator.clipboard.writeText(el).then(() => {
      Toast.fire({
        icon: 'success',
        title: 'Address copied to the clipboard!'
      })
    })
  }


  render() {
    const { classes } = this.props;

    const { account, addressEnsName, modalOpen } = this.state;

    var address = null;
    if (account.address) {
      address = account.address;
    }
    const addressAlias = addressEnsName || address;

    return (
        <div className={classes.root}>
          <div className={`${classes.header} header`}>
            <div className={classes.logo}>
              <a href={"https://foodnear.finance/"}>
                <img
                    /*onClick={() => {
                      this.nav("/");
                    }}*/
                    alt="FoodNear"
                    src={require("../../assets/logo_yellow.svg")}
                    height="60px"
                />
              </a>
            </div>

            <div className={`${classes.icon} icon`}></div>

            <div className={`${classes.links} links`}>
              <div className={`${classes.flexy} flexy`}>
                {/*<Typography*/}
                {/*  variant={"h4"}*/}
                {/*  className={`${classes.link} link`}*/}
                {/*  onClick={() => {*/}
                {/*    this.nav("platform");*/}
                {/*  }}*/}
                {/*>*/}
                {/*  */}
                {/*</Typography>*/}
                <Typography variant={"h4"} className={`${classes.link} link`}>
                  <a href={"https://foodnear.finance/wp-content/themes/meal-away/files/FoodNear_Whitepaper.pdf"} target={"_blank"}>
                    WhitePaper
                  </a>
                </Typography>

              </div>
              <div>
                {address && (
                    <Typography
                        variant={"h4"}
                        className={classes.walletAddress}
                        noWrap
                        onClick={this.addressClicked}
                    >
                      {addressAlias}
                      <div className={classes.connectedDot}></div>
                    </Typography>
                )}
                {!address && (
                    <Typography
                        variant={"h4"}
                        className={classes.walletAddress}
                        noWrap
                        onClick={this.addressClicked}
                    >
                      Connect your wallet
                    </Typography>
                )}
              </div>
            </div>
          </div>

          <div className={classes.investedContainer}>
            <div className={classes.portfolioContainer}>
              <div className={classes.titleBalance}>
                {/*<div className={classes.token}>*/}

                {/*</div>*/}
                <div className={classes.buyModal}>
                  {/*<div className={classes.buyModalImage}>*/}
                  {/*  <img*/}
                  {/*    onClick={() => {*/}
                  {/*      this.nav("/");*/}
                  {/*    }}*/}
                  {/*    alt=""*/}
                  {/*    src={require("../../assets/Token.png")}*/}
                  {/*    height="200px"*/}
                  {/*  />*/}
                  {/*</div>*/}
                  {/*<Typography variant={"h3"}>BUY FON TOKENS WITH BNB NOW!</Typography>*/}
                  {/*<div className={classes.separator}></div>*/}
                  <div className={classes.buyModalContent}>
                    <Typography variant={"h3"}>BUY FOODNEAR TOKENS WITH BNB NOW!</Typography>
                    <TextField
                        className={classes.actionInput}
                        value={this.state.amount}
                        onChange={this.handleAmountChange}
                        /*onChange={(e) => {
                        this.props.setSendAmount(e.target.value);
                      }}*/
                        placeholder={"100 FON"}
                        variant="outlined"
                        type="number"
                        InputProps={{
                          inputProps: { min: 100 },
                          classes: {
                            root: classes.cssOutlinedInput,
                            input: classes.input,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                    />
                    <Typography variant={"h3"} style={{ color: "#484848" }}>
                      Buy Price: 1 BNB/12 121 212 FON
                    </Typography>
                    <Typography
                        variant={"h4"}
                        style={{ color: "#484848", marginTop: "10px" }}
                    >
                      {"You must send"}{" "}
                      {(this.state.amount * this.state.tokenPrice).toFixed(5)}{" "}
                      {"BNB for "}
                      {this.state.amount ? this.state.amount : 0} {"FON"}
                    </Typography>
                    <div className={classes.buttonContainer}>
                      {isMobile && (
                          <Button
                              className={classes.actionButtonDisabled}
                              variant="outlined"
                              color="primary"
                              onClick={this.buyToken}
                              fullWidth
                          >
                            <Typography
                                className={classes.buttonText}
                                variant={"h4"}
                            >
                              BUY FON
                            </Typography>
                          </Button>
                      )}
                      {!isMobile && address && (
                          <Button
                              className={classes.actionButton}
                              variant="outlined"
                              color="primary"
                              onClick={this.buyToken}
                              disabled={!address}
                              fullWidth
                          >
                            <Typography
                                className={classes.buttonText}
                                variant={"h4"}
                            >
                              BUY FON
                            </Typography>
                          </Button>
                      )}
                      {!isMobile && !address && (
                          <LightTooltip title="Please connect your wallet to BUY FON TOKENS">
                        <span>
                          <Button
                              className={classes.actionButtonDisabled}
                              variant="outlined"
                              color="primary"
                              onClick={this.buyToken}
                              disabled={!address}
                              fullWidth
                          >
                            <Typography
                                className={classes.buttonText}
                                variant={"h4"}
                            >
                              BUY FON
                            </Typography>
                          </Button>
                        </span>
                          </LightTooltip>
                      )}
                    </div>
                  </div>
                </div>

                <div
                    className={`${classes.flexy} flexy`}
                    style={{ marginTop: "25px" }}
                >
                  <div
                      className={classes.link}
                      onClick={() =>
                          window.open("https://twitter.com/FONfinance", "_blank")
                      }
                  >
                    <img
                        alt=""
                        src={require("../../assets/twitter_white.svg")}
                        height="30px"
                        className={classes.icon}
                    />
                  </div>
                  <div
                      className={classes.link}
                      onClick={() =>
                          window.open(
                              "https://t.me/foodnearcommunity",
                              "_blank"
                          )
                      }
                  >
                    <img
                        alt=""
                        src={require("../../assets/telegram_white.svg")}
                        height="30px"
                        className={classes.icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.footer}>
            <div className={classes.footerModal}>
              <div className={classes.copyAddress}>
                <div>
                  <Typography
                      variant={"h4"}
                      gutterBottom
                      component="div"
                      className={classes.footerText}
                  >
                    You can buy tokens directly using smart contract address
                  </Typography>
                  <Typography
                      variant={"h4"}
                      onClick
                      gutterBottom
                      component="div"
                      className={classes.footerAddress}
                  >
                    {/*You can buy tokens directly using smart contract address*/}
                    0x9C1f357A3265047fbC55C482eD6f69bDd8F0dA74
                  </Typography>
                </div>
                <div className={classes.buttonClip}>
                  <Tooltip title="Copy address" >
                    <IconButton onClick={() => this.copyCodeToClipboard()}>
                      <img src={iconCopy} alt={"copy address"} width={30}/>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>


            </div>
            <Typography variant={"h4"} className={classes.footerText}>
              Copyright &copy; 2022 FoodNear. All rights reserved.
            </Typography>

          </div>
          {modalOpen && this.renderModal()}
        </div>
    );
  }

  addressClicked = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  renderModal = () => {
    return (
        <UnlockModal
            closeModal={this.closeModal}
            modalOpen={this.state.modalOpen}
        />
    );
  };

  nav = (screen) => {
    this.props.history.push(screen);
  };
}

export default withNamespaces()(withRouter(withStyles(styles)(Presale)));
